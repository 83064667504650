const Grow = (props: { width?: number; height?: number }) => {
  return (
    <svg
      width={props.width || "32"}
      height={props.height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 14.6867H13.3333C13.687 14.6867 14.0261 14.5462 14.2761 14.2961C14.5262 14.0461 14.6667 13.707 14.6667 13.3533V5.35333C14.6667 4.99971 14.5262 4.66057 14.2761 4.41053C14.0261 4.16048 13.687 4.02 13.3333 4.02H5.33333C4.97971 4.02 4.64057 4.16048 4.39052 4.41053C4.14048 4.66057 4 4.99971 4 5.35333V13.3533C4 13.707 4.14048 14.0461 4.39052 14.2961C4.64057 14.5462 4.97971 14.6867 5.33333 14.6867ZM5.33333 28.02H13.3333C13.687 28.02 14.0261 27.8795 14.2761 27.6295C14.5262 27.3794 14.6667 27.0403 14.6667 26.6867V18.6867C14.6667 18.333 14.5262 17.9939 14.2761 17.7439C14.0261 17.4938 13.687 17.3533 13.3333 17.3533H5.33333C4.97971 17.3533 4.64057 17.4938 4.39052 17.7439C4.14048 17.9939 4 18.333 4 18.6867V26.6867C4 27.0403 4.14048 27.3794 4.39052 27.6295C4.64057 27.8795 4.97971 28.02 5.33333 28.02ZM18.6667 28.02H26.6667C27.0203 28.02 27.3594 27.8795 27.6095 27.6295C27.8595 27.3794 28 27.0403 28 26.6867V18.6867C28 18.333 27.8595 17.9939 27.6095 17.7439C27.3594 17.4938 27.0203 17.3533 26.6667 17.3533H18.6667C18.313 17.3533 17.9739 17.4938 17.7239 17.7439C17.4738 17.9939 17.3333 18.333 17.3333 18.6867V26.6867C17.3333 27.0403 17.4738 27.3794 17.7239 27.6295C17.9739 27.8795 18.313 28.02 18.6667 28.02ZM28.3907 8.41067L23.6093 3.62933C23.3593 3.37937 23.0202 3.23895 22.6667 3.23895C22.3131 3.23895 21.974 3.37937 21.724 3.62933L16.9427 8.41067C16.6927 8.66071 16.5523 8.99978 16.5523 9.35333C16.5523 9.70689 16.6927 10.046 16.9427 10.296L21.724 15.0773C21.974 15.3273 22.3131 15.4677 22.6667 15.4677C23.0202 15.4677 23.3593 15.3273 23.6093 15.0773L28.3907 10.296C28.6406 10.046 28.781 9.70689 28.781 9.35333C28.781 8.99978 28.6406 8.66071 28.3907 8.41067Z"
        fill="white"
      />
    </svg>
  );
};

export default Grow;
