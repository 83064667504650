import { Box, Container, Typography } from "@mui/material";
import { AppLogo } from "../../Images/AppLogo";

const Footer = () => {
  return (
    <Box
      sx={(theme) => ({
        background: theme.palette.background.default,
        padding: "32px 0",
      })}
    >
      <Container>
        <Box
          sx={(theme) => ({
            display: "flex",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          })}
        >
          <Box display="flex" alignItems="center" width="100%">
            <AppLogo height={60} width={60} />
            <Box>
              <Typography
                fontSize="20px"
                fontWeight={500}
                fontFamily="PP Hatton"
                lineHeight="32px"
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                })}
              >
                WorldWise: The Wisdom App
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={400}
                fontFamily="Inter"
                lineHeight="16px"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                Powered by Real Human Intelligence.
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="flex-end"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                justifyContent: "center",
                marginTop: "16px",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
                gap: "16px",
                [theme.breakpoints.down("sm")]: {
                  alignItems: "center",
                },
              })}
            >
              <Typography
                fontSize="16px"
                fontWeight={500}
                lineHeight="24px"
                sx={(theme) => ({
                  color: theme.palette.text.primary,
                })}
              >
                Contact
              </Typography>
              <Typography
                fontSize="16px"
                fontWeight={500}
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                Privacy Policy
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "1px",
            background: "#FFFFFF33",
            margin: "36px 0 24px 0",
          }}
        />
        <Box
          display="flex"
          sx={(theme) => ({
            [theme.breakpoints.down("lg")]: {
              alignItems: "center",
              justifyContent: "center",
            },
          })}
        >
          <Typography
            fontSize="16px"
            fontWeight={400}
            fontFamily="Inter"
            lineHeight="24px"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
            })}
          >
            Copyright © 2024 Worldwise Software Limited.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
