import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { AppLogo } from "../../Images/AppLogo";
import Typography from "@mui/material/Typography";
import AppStore from "../../Images/AppStore.png";
import GooglePlay from "../../Images/GooglePlay.png";

type Props = {
  children: React.ReactNode;
};

const ResponsiveAppBar = (props: Props): JSX.Element => {
  return (
    <Box>
      <AppBar
        position="absolute"
        sx={(theme) => ({
          display: "flex",
          background: "rgba(40, 43, 53, 0.10)",
          boxShadow: "0px 1px 0px 0px rgba(0, 0, 0, 0.05)",
          padding: "10px 24px 10px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          [theme.breakpoints.down("sm")]: {
            padding: "10px 14px 10px 14px",
          },
        })}
      >
        <Box
          display="flex"
          sx={{
            alignItems: "center",
            justifyContent: "space-around",
          }}
          width="100%"
        >
          <Box
            display="flex"
            sx={{
              width: "100%",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <AppLogo height={50} width={50} />
            <Typography
              fontSize="20px"
              fontWeight={500}
              fontFamily={"PP Hatton"}
            >
              WorldWise: The Wisdom App
            </Typography>
          </Box>
          <Box
            gap={2}
            sx={{
              display: { xs: "none", sm: "flex" },
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={AppStore} alt="App Store" height={34} width={102} />
            <img src={GooglePlay} alt="Google Play" height={34} width={102} />
          </Box>
        </Box>
      </AppBar>
      {props.children}
    </Box>
  );
};
export default ResponsiveAppBar;
