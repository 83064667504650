import { Box, Typography } from "@mui/material";

export type ContentItemProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
};

export const ContentItem = (props: ContentItemProps) => {
  const { icon, title, description } = props;

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
        padding: "20px 0",
        [theme.breakpoints.down("lg")]: {
          maxWidth: "320px",
        },
      })}
    >
      {icon}
      <Typography
        fontSize="24px"
        fontWeight={500}
        lineHeight="20px"
        align="center"
        sx={(theme) => ({
          color: theme.palette.text.primary,
        })}
      >
        {title}
      </Typography>
      <Typography
        fontSize="16px"
        fontWeight={500}
        lineHeight="24px"
        align="center"
        sx={(theme) => ({
          color: theme.palette.text.secondary,
        })}
      >
        {description}
      </Typography>
    </Box>
  );
};
