import { Box } from "@mui/material";
import iPhone from "../../Images/iPhone.png";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Quote } from "../../Images/Icons/Quote";
import AppStore from "../../Images/AppStore.png";
import GooglePlay from "../../Images/GooglePlay.png";

const Hero = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={(theme) => ({
        background: theme.palette.background.default,
        width: "100%",
        pt: "56px",
        justifyContent: "center",
        overflow: "hidden",
      })}
    >
      <Box
        sx={(theme) => ({
          width: "450px",
          height: "500px",
          transform: "rotate(-33.42deg)",
          transformOrigin: "0 0",
          mixBlendMode: "lighten",
          background: "rgba(171.06, 87.13, 255, 0.40)",
          boxShadow: "180px 180px 180px ",
          filter: "blur(90px)",
          top: "180px",
          position: "absolute",
          zIndex: 0,
          [theme.breakpoints.down("lg")]: {
            width: "200px",
            height: "250px",
            filter: "blur(60px)",
          },
          [theme.breakpoints.down("sm")]: {
            width: "150px",
            height: "200px",
            filter: "blur(60px)",
          },
        })}
      />
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          maxWidth: "510px",
          gap: "60px",
          pt: "30px",
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            height: "100%",
          },
          [theme.breakpoints.down("sm")]: {
            gap: "0px",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            maxWidth: "560px",
            gap: "16px",
            [theme.breakpoints.down("lg")]: {
              alignSelf: "center",
              paddingTop: "32px",
              paddingBottom: "32px",
            },
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                justifyContent: "start",
              },
            })}
          >
            <Quote />
          </Box>

          <Box>
            <Typography
              fontSize="48px"
              fontWeight={500}
              fontFamily="PP Hatton"
              lineHeight="56px"
              sx={(theme) => ({
                color: theme.palette.text.primary,
                [theme.breakpoints.down("sm")]: {
                  fontSize: "40px",
                },
              })}
            >
              Discover. Learn. Grow. Be WorldWise.
            </Typography>
            <Typography
              fontSize="16px"
              fontWeight={500}
              fontFamily="Visby"
              lineHeight="24px"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                [theme.breakpoints.down("sm")]: {
                  display: "flex",
                },
              })}
            >
              Get quotes to motivate, inspire, and learn from daily. Curated
              especially for you, by real humans, with real feelings, emotions,
              and thoughts.
            </Typography>
          </Box>
          <Box
            display="flex"
            sx={(theme) => ({
              width: "100%",
              mt: "8px",
              flexDirection: "column",
              paddingTop: "16px",
              gap: "6px",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "row",
                justifyContent: "center",
              },
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "center",
              },
            })}
          >
            <img src={AppStore} alt="App Store" width={166} height={55} />
            <img src={GooglePlay} alt="Google Play" width={166} height={55} />
          </Box>
        </Box>
        <Box width="100%" display="flex" justifyContent="center">
          <Box
            sx={(theme) => ({
              width: "564px",
              height: "auto",
              position: "relative",
              overflow: "hidden",
              [theme.breakpoints.down("sm")]: {
                width: "320px",
              },
            })}
          >
            <img
              srcSet={`${iPhone}?w=320 320w, ${iPhone}?w=564 564w`}
              sizes="(max-width: 320px) 280px, (max-width: 564px) 100vw"
              src={iPhone}
              loading="lazy"
              alt="iPhone"
              style={{
                width: "100%",
                height: "auto",
                position: "relative",
                zIndex: 1,
              }}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
