import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    background: {
      default: "#21222B",
    },
    primary: {
      main: "#21222B",
    },
    secondary: {
      main: "#FF6B6B",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#B7B7B7",
    },
  },
  typography: {
    fontFamily: "Visby",
  },
});

theme = responsiveFontSizes(theme);

export { theme };
