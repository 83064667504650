import { Box, Container, Typography } from "@mui/material";
import { ContentItemProps, ContentItem } from "./ContentItem";
import GuitarIcon from "../../Images/Icons/Guitar";
import MessageIcon from "../../Images/Icons/Message";
import GrowIcon from "../../Images/Icons/Grow";
import InsipirationBackgroundLarge from "../../Images/InsipirationBackgroundLarge.png";
import InsipirationBackgroundXL from "../../Images/InsipirationBackgroundXL.png";
import InsipirationBackgroundXS from "../../Images/InsipirationBackgroundXS.png";
import Mountain from "../../Images/Mountain.png";
import AppStore from "../../Images/AppStore.png";
import GooglePlay from "../../Images/GooglePlay.png";

const ContentItemData: ContentItemProps[] = [
  {
    icon: <GuitarIcon />,
    title: "Discover",
    description:
      "Enjoy new quotes from new and favorite cultures and interests.",
  },
  {
    icon: <MessageIcon />,
    title: "Learn",
    description:
      "Pick up ways of life, thinking, and wisdom from cultures all around the world.",
  },
  {
    icon: <GrowIcon />,
    title: "Grow",
    description:
      "Become wiser with learnings and sayings from people throughout history.",
  },
];

const Content = () => {
  return (
    <>
      <Box
        sx={(theme) => ({
          background: theme.palette.background.default,
        })}
      >
        <Box
          sx={() => ({
            width: "100%",
            height: "auto",
            padding: "56px 0",
          })}
        >
          <Container
            sx={(theme) => ({
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              gap: "50px",
              alignItems: "center",
              width: "100%",
              height: "100%",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
              },
              [theme.breakpoints.down("sm")]: {},
            })}
          >
            {ContentItemData.map((item, index) => (
              <ContentItem key={index} {...item} />
            ))}
          </Container>
        </Box>
        <picture>
          <source
            media="(max-width: 599px)"
            srcSet={InsipirationBackgroundXS}
          />
          <source
            media="(max-width: 1199px)"
            srcSet={InsipirationBackgroundLarge}
          />
          <source
            media="(min-width: 1200px)"
            srcSet={InsipirationBackgroundXL}
          />
          <img
            src={InsipirationBackgroundXL} // Fallback image
            loading="eager"
            alt="Inspiration Background"
            width="100%"
          />
        </picture>
        <Container
          sx={{
            padding: "32px 0",
          }}
        >
          <Box>
            <Typography
              fontSize="16px"
              fontWeight={500}
              lineHeight="28px"
              align="center"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              Insanely great - beautiful and simple. The best daily quotes app,
              hands down.
            </Typography>
            <Typography
              fontSize="12px"
              fontWeight={500}
              lineHeight="34px"
              align="center"
              color="#B7B7B7"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
              })}
            >
              — User review
            </Typography>
          </Box>
        </Container>

        <Box
          sx={(theme) => ({
            position: "relative",
            height: "100vh",
            maxHeight: "700px",
            background: `url(${Mountain}) lightgray 0px 0px / 100% 161.28% no-repeat`,
            backgroundBlendMode: "screen",
            backgroundColor: theme.palette.background.default,
            alignSelf: "stretch",
            [theme.breakpoints.down("lg")]: {
              maxHeight: "600px",
              background: `url(${Mountain}) lightgray -302.101px -3px / 200.492% 158.013% no-repeat`,
              backgroundBlendMode: "screen",
              backgroundColor: theme.palette.background.default,
            },
            [theme.breakpoints.down("sm")]: {
              maxHeight: "250px",
              background: `url(${Mountain}) lightgray -159.55px 0px / 186.108% 151.25% no-repeat`,
              backgroundBlendMode: "screen",
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Container>
            <Box>
              <Typography
                fontSize="16px"
                fontWeight={500}
                lineHeight="28px"
                align="center"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                Artificial intelligence is no match for natural stupidity.
              </Typography>
              <Typography
                fontSize="12px"
                fontWeight={500}
                lineHeight="34px"
                align="center"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                — Albert Einstein
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              mt={2}
              gap={2}
            >
              <img src={AppStore} alt="App Store" width={166} height={55} />
              <img src={GooglePlay} alt="Google Play" width={166} height={55} />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Content;
