import { Box } from "@mui/material";
import Hero from "../Hero";
import Content from "../Content";
import Footer from "../Footer";

const Home = () => {
  return (
    <Box>
      <Hero />
      <Content />
      <Footer />
    </Box>
  );
};

export default Home;
