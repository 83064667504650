const Message = (props: { width?: number; height?: number }) => {
  return (
    <svg
      width={props.width || "32"}
      height={props.height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.3933 10.38L29.3267 18.78C29.3277 19.6083 29.1646 20.4285 28.8467 21.1933C28.5317 21.956 28.0692 22.6489 27.4857 23.2324C26.9023 23.8159 26.2093 24.2784 25.4467 24.5933C24.6818 24.9112 23.8616 25.0744 23.0333 25.0733H19.9267L17.26 27.74C17.0941 27.9124 16.8944 28.0486 16.6733 28.14C16.4435 28.2463 16.1932 28.3009 15.94 28.3C15.6882 28.3011 15.4387 28.2512 15.2067 28.1533C14.9744 28.05 14.7657 27.9002 14.5933 27.7133L11.9933 25.1267H8.88667C8.06084 25.1267 7.24313 24.9638 6.48033 24.6474C5.71754 24.3309 5.02464 23.8672 4.44132 23.2826C3.85799 22.698 3.39569 22.0041 3.08087 21.2407C2.76606 20.4772 2.60491 19.6592 2.60667 18.8333V10.0333C2.60435 9.20635 2.76574 8.38708 3.08154 7.62276C3.39733 6.85843 3.86129 6.16417 4.44667 5.58C5.62984 4.41075 7.22326 3.75042 8.88667 3.74H23.1C23.9269 3.73825 24.7461 3.89983 25.5104 4.21548C26.2748 4.53112 26.9692 4.99462 27.554 5.57936C28.1387 6.1641 28.6022 6.85857 28.9179 7.62291C29.2335 8.38724 29.3951 9.20639 29.3933 10.0333V10.38Z"
        fill="white"
      />
    </svg>
  );
};

export default Message;
