export const Quote = (props: { width?: string; height?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="26"
      viewBox="0 0 31 26"
      fill="none"
    >
      <g opacity="0.24">
        <path
          d="M24.6 25.2C22 25.2 20.0333 24.1333 18.7 22C17.5667 20.2 17 18 17 15.4C17 12.0667 17.8333 9.06667 19.5 6.4C21.2333 3.73334 23.8333 1.6 27.3 0L28.2 1.8C26.1333 2.66667 24.3333 4.03334 22.8 5.9C21.3333 7.76667 20.6 9.66667 20.6 11.6C20.6 12.4 20.7 13.1 20.9 13.7C21.9667 12.8333 23.2 12.4 24.6 12.4C26.4 12.4 27.9 13 29.1 14.2C30.3667 15.3333 31 16.8667 31 18.8C31 20.6 30.3667 22.1333 29.1 23.4C27.9 24.6 26.4 25.2 24.6 25.2ZM7.60001 25.2C5.00001 25.2 3.03333 24.1333 1.7 22C0.566664 20.2 0 18 0 15.4C0 12.0667 0.833333 9.06667 2.5 6.4C4.23333 3.73334 6.83334 1.6 10.3 0L11.2 1.8C9.13333 2.66667 7.33334 4.03334 5.8 5.9C4.33334 7.76667 3.60001 9.66667 3.60001 11.6C3.60001 12.4 3.69999 13.1 3.89999 13.7C4.96666 12.8333 6.20001 12.4 7.60001 12.4C9.40001 12.4 10.9 13 12.1 14.2C13.3667 15.3333 14 16.8667 14 18.8C14 20.6 13.3667 22.1333 12.1 23.4C10.9 24.6 9.40001 25.2 7.60001 25.2Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
