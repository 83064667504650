import { FC } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { LandingPage } from "./Components";
import Home from "./Containers/Home";
import Wrapper from "./Containers/Wrapper/Wrapper";

const routes = [
  {
    path: "*",
    Component: LandingPage,
  },
  {
    path: "/home",
    Component: Home,
  },
];

export const AppRoutes: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, Component }) => {
          if (path === "*") {
            return <Route key={path} path={path} element={<Component />} />;
          }
          return (
            <Route
              key={path}
              path={path}
              element={
                <Wrapper>
                  <Component />
                </Wrapper>
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};
